(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('ServicoDialogController', ServicoDialogController);

    ServicoDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Servico', 'Upload', 'AtributoGenerico'];

    function ServicoDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, Servico, Upload, AtributoGenerico) {
        var vm = this;

        vm.servico = entity;
        vm.clear = clear;
        vm.save = save;
        vm.upload = upload;
        vm.file = null;
        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.servico.id !== null) {
                Servico.update(vm.servico, onSaveSuccess, onSaveError);
            } else {
                Servico.save(vm.servico, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('mySkillsPersonalApp:servicoUpdate', result);
            var agrupador = {vinculo: result.id, tipo: 'SERVICO', atributos: []};
            for (var i = 0; i < vm.atributos.length; i++) {
                var atributo = vm.atributos[i];
                agrupador.atributos.push({
                    atributo: atributo,
                    tipoVinculo: agrupador.tipo,
                    vinculo: agrupador.vinculo,
                    valor: atributo.tipoCampo === 'MULTIPLO' ? atributo.valor.join(',') : atributo.valor
                });

            }

            AtributoGenerico.saveVinculos(agrupador, function (data) {
                $uibModalInstance.close(result);
            });


            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function upload($file) {
            vm.file = $file;
            vm.progressPercentage = 0;
            Upload.upload({
                url: '/api/upload/imagem',
                data: {
                    file: vm.file,
                }
            }).then(function (resp) {
                console.log(" finalizando a chamada ", resp.data.conteudoFormatado);
                vm.servico.imagem = resp.data.conteudoFormatado;
            }, function (resp) {
                console.log('Error status: ' + resp.status);
                vm.progressPercentage = null;
            }, function (evt) {
                vm.progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                console.log('progress: ' + vm.progressPercentage + '% ' + evt.config.data.file.name);
            });
        };


    }
})();
