(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('ServicoDetailController', ServicoDetailController);

    ServicoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Servico', 'Empregador', '$location', 'Parceiro'];

    function ServicoDetailController($scope, $rootScope, $stateParams, previousState, entity, Servico, Empregador, $location, Parceiro) {
        var vm = this;

        vm.servico = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('mySkillsPersonalApp:servicoUpdate', function (event, result) {
            vm.servico = result;
        });
        $scope.$on('$destroy', unsubscribe);

        var host = $location.host();
        vm.subdomain = host.split('.')[0];

        function carregarParceiro() {
            Parceiro.getPublico({identificador: vm.subdomain}, function (data) {
                vm.parceiro = data;
                vm.idParceiro = vm.parceiro.id;
                buscarEmpresasPorServico();

            }, function () {
                buscarEmpresasPorServico();

            });
        }

        carregarParceiro();

        function buscarEmpresasPorServico() {
            vm.filtros = {
                page: 0,
                pageSize: 99,
                parceiroId: vm.idParceiro ? vm.idParceiro : null,
                servicoId: vm.servico.id
            };

            Empregador.filtrar(vm.filtros, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.empregadors = data;
            }

            function onError(error) {
                vm.carregando = false;
            }
        }

        buscarEmpresasPorServico();
    }
})();
