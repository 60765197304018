(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('ServicoSelectController', ServicoSelectController);

    ServicoSelectController.$inject = ['$scope', '$state', 'Servico', 'ServicoSearch', 'ParseLinks', 'AlertService', '$uibModalInstance', 'ConfirmService'];

    function ServicoSelectController($scope, $state, Servico, ServicoSearch, ParseLinks, AlertService, $uibModalInstance, ConfirmService) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.page = 1;
        vm.transition = transition;
        vm.itemsPerPage = 24;
        vm.clear = clear;
        vm.loadAll = loadAll;
        vm.selecionar = selecionar;


        loadAll();

        function loadAll() {

            Servico.query({
                page: vm.page - 1,
                size: vm.itemsPerPage,
                search: vm.currentSearch
            }, onSuccess, onError);


            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.servicos = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            vm.page = vm.page + 1;
            vm.loadAll();
        }


        function selecionar(servico) {
            ConfirmService.confirm({
                title: "Confirme a Operação!",
                text: "Ao continuar você vinculará o serviço " + servico.titulo + " ao seu perfil.",
                confirm: function () {
                    Servico.vincularUsuario(servico);
                    $uibModalInstance.close({aceite: false});
                },
                cancel: function () {
                }
            });


        }


        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
    }
})();
