(function() {
    'use strict';
    angular
        .module('mySkillsPersonalApp')
        .factory('Servico', Servico);

    Servico.$inject = ['$resource'];

    function Servico ($resource) {
        var resourceUrl =  'api/servicos/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'vincularUsuario': { url: 'api/vincular-servico-usuario', method: 'POST'},
            'removerVinculo': { url: 'api/remover-servico-usuario', method: 'POST'},
            'buscarServicoUsuario': { url: 'api/buscar-servico-usuario/:id', method: 'GET', isArray: true},
            'contarServicoUsuario': { url: 'api/contar-servico-usuario/:id', method: 'GET'},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
