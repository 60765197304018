(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('EmpregadorDetailController', EmpregadorDetailController);

    EmpregadorDetailController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'previousState', 'entity', 'Vaga', '$uibModal', 'Mensagem', 'ConfirmService', 'Empregador', 'Principal', '$timeout', '$location', 'Parceiro', 'Servico'];

    function EmpregadorDetailController($scope, $rootScope, $state, $stateParams, previousState, entity, Vaga, $uibModal, Mensagem, ConfirmService, Empregador, Principal, $timeout, $location, Parceiro, Servico) {
        var vm = this;

        vm.empregador = entity;
        vm.buscarVaga = buscarVaga;
        vm.verImagem = verImagem;
        vm.desmarcarVaga = desmarcarVaga;
        vm.previousState = previousState.name;
        vm.enviarMensagem = enviarMensagem;
        vm.addInteresse = addInteresse;
        vm.isAuthenticated = Principal.isAuthenticated;

        var host = $location.host();
        vm.subdomain = host.split('.')[0];


        vm.slickConfig = {
            enabled: true,
            autoplay: true,
            draggable: false,
            dots: true,
            arrows: false,
            autoplaySpeed: 7000,
            method: {},
            responsive: [{
                breakpoint: 1200, settings: {
                    slidesToShow: 1, slidesToScroll: 1, dots: true, arrows: false
                }
            }, {
                breakpoint: 1008, settings: {
                    slidesToShow: 1, slidesToScroll: 1, dots: true, arrows: false
                }
            }

            ]
        };

        function verImagem(anexo) {
            $uibModal.open({
                templateUrl: 'app/entities/imagem/imagem-edit.html',
                controller: 'ImagemController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    imagem: function () {
                        return anexo.conteudo;
                    }
                }
            });
        }


        function carregarParceiro() {
            Parceiro.getPublico({identificador: vm.subdomain}, function (data) {
                vm.parceiro = data;
            });
        }


        carregarParceiro();

        function carregarVagas() {
            Vaga.getVagasEmpregador({id: vm.empregador.id}, function (data) {
                vm.vagas = data;
                if (vm.isAuthenticated) {
                    Vaga.getVagasUsuario({}, function (data2) {
                        vm.vagasUsuario = data2;
                        for (var i = 0; i < vm.vagas.length; i++) {
                            var vaga = vm.vagas[i];
                            for (var j = 0; j < vm.vagasUsuario.length; j++) {
                                var vagaUsuario = vm.vagasUsuario[j];
                                if (vaga.vagaCorporateId == vagaUsuario.id) {
                                    vaga.participando = true;
                                }
                            }
                        }
                    });
                }

            });
        }

        function carregarServicos() {
            if (vm.empregador.id) {
                Servico.buscarServicoUsuario({
                    id: vm.empregador.user.id,
                }, function (data) {
                    vm.servicos = data;
                });
            }
        }

        carregarServicos();

        function buscarAnexos() {
            Empregador.buscarAnexo({id: vm.empregador.id}, function (data) {
                vm.anexos = data;
                vm.videos = [];
                vm.imagens = [];
                for (var i = 0; i < vm.anexos.length; i++) {
                    if (vm.anexos[i].tipo == 'IMAGEM') {
                        vm.imagens.push(vm.anexos[i]);
                    }
                    if (vm.anexos[i].tipo == 'VIDEO') {
                        vm.videos.push(vm.anexos[i]);
                    }
                }
                $timeout(function () {
                    for (var i = 0; i < vm.videos.length; i++) {
                        var anexo = vm.videos[i];
                        var video = document.getElementById('video-preview-' + anexo.id);
                        video.src = null;
                        video.muted = false;
                        video.volume = 1;
                        video.src = anexo.conteudo;
                    }
                }, 2000);
            });
        }

        carregarVagas();
        buscarAnexos();

        function desmarcarVaga(vagaId) {
            ConfirmService.swal({
                    title: "Atenção!",
                    text: "Ao desmarcar essa profissão você será removido da base de dados de todas as empresas que a oferecem.",
                    type: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#f9b36d",
                    confirmButtonText: "Revomer PROFISSÃO",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        Vaga.deleteVagaUsuarioById({id: vagaId}, function (data) {
                            carregarVagas();
                        });
                    }
                }
            );
        }

        function buscarVaga(vaga) {
            $uibModal.open({
                templateUrl: 'app/entities/vaga/vaga-dialog.html',
                controller: 'VagaDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['Vaga', function (Vaga) {
                        return Vaga.get({id: vaga.coporateId}).$promise;
                    }]
                }
            }).result.then(function () {
                carregarVagas();
            }, function () {
                carregarVagas();
            });
            // Vaga.get({id: vaga.id}, function (data) {
            //     vm.vagaSelecionada = data;
            //     console.log("Recuperou", data);
            // });
        }

        function enviarMensagem() {
            Mensagem.save(vm.mensagem, function (mensagem) {
                $state.go("mensagem");
                ConfirmService.swal({
                    title: "Sua mensagem foi enviada!",
                    text: "Aguarde a resposta de " + vm.empregador.nomeFantasia,
                    timer: 3000,
                    showConfirmButton: false
                });
            });
            inicarMensagem();
        }


        function inicarMensagem() {
            vm.mensagem = {texto: "", destino: vm.empregador.user, publicadoEm: new Date()};
        }

        inicarMensagem();

        function addInteresse(marcarInteresse) {
            Empregador.addInteresse({id: vm.empregador.id}, function () {
                $state.reload();

                if (marcarInteresse) {
                    ConfirmService.swal({
                        type: "success",
                        title: "Ótima escolha!",
                        text: "Agora a Empresa " + vm.empregador.nomeFantasia + " já sabe do seu interesse!",
                        showCancelButton: false,
                        confirmButtonColor: "#18a689", confirmButtonText: "OK",
                    });
                }
            });
        }

        function buscarInteresse() {
            Empregador.buscarInteresse({id: vm.empregador.id}, function (data) {
                vm.jaTemInteresse = !!data && data.id;
            });
        }

        buscarInteresse();
    }
})();
