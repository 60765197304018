(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('empregador', {
                parent: 'home',
                url: '/empregador',
                data: {
                    authorities: [],
                    pageTitle: 'mySkillsPersonalApp.empregador.detail.title'
                },
                params: {
                    idParceiro: null
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/empregador/empregador-dialog.html',
                        controller: 'EmpregadorDialogController',
                        controllerAs: 'vm'
                    },
                    'dashboards-empreesa@empregador': {
                        templateUrl: 'app/entities/dashboards/empregador-dashboard.html',
                        controller: 'EmpregadorDashBoardController',
                        controllerAs: 'vmds'
                    },
                    'dashboards-pessoa@empregador': {
                        templateUrl: 'app/entities/dashboards/empregador-pessoa-dashboard.html',
                        controller: 'EmpregadorPessoaDashBoardController',
                        controllerAs: 'vmdp'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('empregador');
                        $translatePartialLoader.addPart('quantidadeFuncionarios');
                        $translatePartialLoader.addPart('nacionalidade');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Empregador', function ($stateParams, Empregador) {
                        return Empregador.getByUser().$promise;
                    }],
                    pagingParams: ['$stateParams', function ($stateParams) {
                        return {
                            idParceiro: $stateParams.idParceiro
                        };
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'empregador',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('empresas', {
                parent: 'home',
                url: '/empresas?page&' +
                    'pageSize&' +
                    'nome&' +
                    'cidade&' +
                    'estado&' +
                    'bairro&' +
                    'nacionalidade&' +
                    'quantidadeFuncionario',
                data: {
                    authorities: [],
                    pageTitle: 'mySkillsPersonalApp.empregador.detail.title'
                },
                params: {
                    page: null,
                    pageSize: null,
                    nome: null,
                    cidade: null,
                    estado: null,
                    bairro: null,
                    nacionalidade: null,
                    quantidadeFuncionario: null,

                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/empregador/empregadors.html',
                        controller: 'EmpregadorController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: $stateParams.page,
                            pageSize: $stateParams.pageSize,
                            nome: $stateParams.nome,
                            cidade: $stateParams.cidade,
                            estado: $stateParams.estado,
                            bairro: $stateParams.bairro,
                            nacionalidade: $stateParams.nacionalidade,
                            quantidadeFuncionario: $stateParams.quantidadeFuncionario,
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('empregador');
                        $translatePartialLoader.addPart('quantidadeFuncionarios');
                        $translatePartialLoader.addPart('nacionalidade');
                        return $translate.refresh();
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'empregador',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('buscar-pessoas', {
                parent: 'empregador',
                url: '/buscar-perfis/{empregadorId}?{interessados}&{premium}&{filtrarCompetencias}&{cursoId}',
                data: {
                    authorities: ['ROLE_EMPREGADOR', 'ROLE_ADMIN'],
                    pageTitle: 'mySkillsPersonalApp.empregador.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/empregador/buscar-pessoa-simples.html',
                        controller: 'BuscarPessoaSimplesController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    interessados: {
                        value: 'false'
                    },
                    premium: {
                        value: 'false'
                    },
                    filtrarCompetencias: {
                        value: 'false'
                    },
                    cursoId: {
                        value: ''
                    },
                    empregadorId: {
                        value: ''
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('empregador');
                        $translatePartialLoader.addPart('remuneracao');
                        return $translate.refresh();
                    }],
                    pagingParams: ['$stateParams', function ($stateParams) {
                        return {
                            apenasInteressados: $stateParams.interessados,
                            apenasComprados: $stateParams.premium,
                            empregadorId: $stateParams.empregadorId,
                            cursoId: $stateParams.cursoId,
                            filtrarCompetencias: $stateParams.filtrarCompetencias
                        };
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'empregador',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('comunicar-pessoas', {
                parent: 'empregador',
                url: '/comunicar-perfis/{empregadorId}?{interessados}&{premium}&{filtrarCompetencias}&{cursoId}',
                data: {
                    authorities: ['ROLE_EMPREGADOR', 'ROLE_ADMIN'],
                    pageTitle: 'mySkillsPersonalApp.empregador.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/empregador/comunicar-pessoa.html',
                        controller: 'ComunicarPessoaController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    interessados: {
                        value: 'false'
                    },
                    premium: {
                        value: 'false'
                    },
                    filtrarCompetencias: {
                        value: 'false'
                    },
                    cursoId: {
                        value: ''
                    },
                    empregadorId: {
                        value: ''
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('empregador');
                        $translatePartialLoader.addPart('remuneracao');
                        return $translate.refresh();
                    }],
                    pagingParams: ['$stateParams', function ($stateParams) {
                        return {
                            apenasInteressados: $stateParams.interessados,
                            apenasComprados: $stateParams.premium,
                            empregadorId: $stateParams.empregadorId,
                            cursoId: $stateParams.cursoId,
                            filtrarCompetencias: $stateParams.filtrarCompetencias
                        };
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'empregador',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('empregador-vaga', {
                parent: 'empregador',
                url: '/empregador-profissao/{vagaEmpregadorId}/{id}/{empregadorId}?cidades',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'mySkillsPersonalApp.empregador.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/empregador/pessoa-vaga.html',
                        controller: 'PessoaVagaDialogController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    cidades: {
                        value: ''
                    },
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('empregador');
                        $translatePartialLoader.addPart('remuneracao');
                        return $translate.refresh();
                    }],
                    pagingParams: ['$stateParams', function ($stateParams) {
                        return {
                            cidades: $stateParams.cidades
                        };
                    }],
                    entity: ['$stateParams', 'Vaga', function ($stateParams, Vaga) {
                        return Vaga.get({id: $stateParams.id}).$promise;
                    }],
                    vagaEmpregadorId: ['$stateParams', function ($stateParams) {
                        return $stateParams.vagaEmpregadorId;
                    }],
                    empregadorId: ['$stateParams', function ($stateParams) {
                        return $stateParams.empregadorId;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'empregador',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('empregador-dashboard', {
                parent: 'empregador',
                url: '/empregador-dashboard/{idParceiro}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'mySkillsPersonalApp.empregador.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/dashboards/empregador-dashboard.html',
                        controller: 'EmpregadorDashBoardController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    cidades: {
                        value: ''
                    },
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('empregador');
                        $translatePartialLoader.addPart('remuneracao');
                        return $translate.refresh();
                    }],
                    idParceiro: ['$stateParams', function ($stateParams) {
                        return $stateParams.idParceiro;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'empregador',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('empregador-plano-credito', {
                parent: 'empregador',
                url: '/planos-credito',
                data: {
                    authorities: ['ROLE_EMPREGADOR'],
                    pageTitle: 'mySkillsPersonalApp.empregador.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/empregador/empregador-escolher-plano-dialog.html',
                        controller: 'EmpregadorEscolherPlanoController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    cidades: {
                        value: ''
                    },
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('empregador');
                        return $translate.refresh();
                    }],
                    entity: ['PlanoCredito', function (PlanoCredito) {
                        //return User.get({login: $stateParams.login});
                        return null;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'empregador',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('vincular-vaga', {
                parent: 'empregador',
                url: '/vincular-vaga/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'mySkillsPersonalApp.empregador.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/empregador/vincular-vaga.html',
                        controller: 'VincularVagaDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('empregador');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Empregador', function ($stateParams, Empregador) {
                        return Empregador.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'empregador',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('empregador-detail', {
                parent: 'home',
                url: '/empregador/{id}',
                data: {
                    authorities: [],
                    pageTitle: 'mySkillsPersonalApp.empregador.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/empregador/empregador-detail.html',
                        controller: 'EmpregadorDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('empregador');
                        $translatePartialLoader.addPart('quantidadeFuncionarios');
                        $translatePartialLoader.addPart('nacionalidade');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Empregador', function ($stateParams, Empregador) {
                        return Empregador.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'empregador',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('empregador-edit', {
                parent: 'home',
                url: '/empregador-edit/{id}',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'mySkillsPersonalApp.empregador.detail.title'
                },
                params: {
                    idParceiro: null
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/empregador/empregador-dialog.html',
                        controller: 'EmpregadorDialogController',
                        controllerAs: 'vm'
                    },
                    'dashboards-empreesa@empregador-edit': {
                        templateUrl: 'app/entities/dashboards/empregador-dashboard.html',
                        controller: 'EmpregadorDashBoardController',
                        controllerAs: 'vmds'
                    },
                    'dashboards-pessoa@empregador-edit': {
                        templateUrl: 'app/entities/dashboards/empregador-pessoa-dashboard.html',
                        controller: 'EmpregadorPessoaDashBoardController',
                        controllerAs: 'vmdp'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('empregador');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Empregador', function ($stateParams, Empregador) {
                        return Empregador.get({id: $stateParams.id}).$promise;
                    }],
                    pagingParams: ['$stateParams', function ($stateParams) {
                        return {
                            idParceiro: $stateParams.idParceiro
                        };
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'empregador',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('empregador-transmissoes', {
                parent: 'home',
                url: '/empregador-transmissoes/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_EMPREGADOR'],
                    pageTitle: 'mySkillsPersonalApp.empregador.detail.title'
                },
                params: {
                    idParceiro: null
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/empregador/empregador-transmissoes.html',
                        controller: 'EmpregadorDialogController',
                        controllerAs: 'vm'
                    },
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('empregador');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Empregador', function ($stateParams, Empregador) {
                        return Empregador.get({id: $stateParams.id}).$promise;
                    }],
                    pagingParams: ['$stateParams', function ($stateParams) {
                        return {
                            idParceiro: $stateParams.idParceiro
                        };
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'empregador',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('empregador-imagens-videos', {
                parent: 'home',
                url: '/empregador-imagens-videos/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_EMPREGADOR'],
                    pageTitle: 'mySkillsPersonalApp.empregador.detail.title'
                },
                params: {
                    idParceiro: null
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/empregador/empregador-imagens-videos.html',
                        controller: 'EmpregadorDialogController',
                        controllerAs: 'vm'
                    },
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('empregador');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Empregador', function ($stateParams, Empregador) {
                        return Empregador.get({id: $stateParams.id}).$promise;
                    }],
                    pagingParams: ['$stateParams', function ($stateParams) {
                        return {
                            idParceiro: $stateParams.idParceiro
                        };
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'empregador',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('empregador-profissoes', {
                parent: 'home',
                url: '/empregador-profissoes/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_EMPREGADOR'],
                    pageTitle: 'mySkillsPersonalApp.empregador.detail.title'
                },
                params: {
                    idParceiro: null
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/empregador/empregador-profissoes.html',
                        controller: 'EmpregadorDialogController',
                        controllerAs: 'vm'
                    },
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('empregador');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Empregador', function ($stateParams, Empregador) {
                        return Empregador.get({id: $stateParams.id}).$promise;
                    }],
                    pagingParams: ['$stateParams', function ($stateParams) {
                        return {
                            idParceiro: $stateParams.idParceiro
                        };
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'empregador',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('empregador-servicos', {
                parent: 'home',
                url: '/empregador-servicos/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_EMPREGADOR'],
                    pageTitle: 'mySkillsPersonalApp.empregador.detail.title'
                },
                params: {
                    idParceiro: null
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/empregador/empregador-servicos.html',
                        controller: 'EmpregadorDialogController',
                        controllerAs: 'vm'
                    },
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('empregador');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Empregador', function ($stateParams, Empregador) {
                        return Empregador.get({id: $stateParams.id}).$promise;
                    }],
                    pagingParams: ['$stateParams', function ($stateParams) {
                        return {
                            idParceiro: $stateParams.idParceiro
                        };
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'empregador',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('empregador-user-detail', {
                parent: 'app',
                url: '/empregador-user/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'mySkillsPersonalApp.empregador.detail.title'
                },
                views: {
                    'navbar@': {
                        templateUrl: 'app/layouts/navbar/navbar.html',
                        controller: 'NavbarController',
                        controllerAs: 'vm'
                    },
                    'content@': {
                        templateUrl: 'app/entities/empregador/empregador-detail.html',
                        controller: 'EmpregadorDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('empregador');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Empregador', function ($stateParams, Empregador) {
                        return Empregador.getByUserId({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'home',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            });
    }

})();
